@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  span {
    @apply text-white;
  }
  a {
    @apply text-white;
  }
  h1 {
    @apply text-4xl;
    @apply text-white;
    @apply my-5;
  }
  h2 {
    @apply text-3xl;
    @apply text-white;
    @apply my-3
  }
  h3 {
    @apply text-2xl;
    @apply text-white;
  }
  h4 {
    @apply text-xl;
    @apply text-white;
  }
}
